const idPortalApi = "cf32b6ff2f299a0793280b414e616082382d03de";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  //config botões e imagens
  dnsPortal: "paraibano.ma.leg.br",
  banner_defalt: true, //se não quiser o banner transparencia azul padrao

  NomePortal: "Paraibano",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 4, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://paraibano.ma.leg.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `
  https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5273.150015089942!2d-43.9925382!3d-6.4312857!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x786bcf67eeca9d5%3A0x60777158a3ba400b!2sParaibano%2C%20MA%2C%2065670-000!5e1!3m2!1spt-BR!2sbr!4v1731349572360!5m2!1spt-BR!2sbr
  `,
};
